import React from 'react';

const LinkCard = ({ link }) => {
  const imageUrl = link.image ? link.image : link.platform.image;
  return (
    <a
      href={
        link.platform.type === 'url'
          ? link.value.includes('http')
            ? link.value
            : 'https://' + link.value
          : link.platform.webBaseURL + link.value
      }
      target="_blank"
      rel="noreferrer"
    >
      <img src={imageUrl} alt="link" className="card-img-top p-1" />
      <br />
      <p>{link.title ? link.title : link.platform.title}</p>
    </a>
  );
};

export default LinkCard;
