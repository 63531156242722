import Loader from 'components/loader';
import Message from 'components/message';
import { useAppContext } from 'hooks/useAppContext';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextField from 'components/custom-text-field';
import CustomPhoneField from 'components/custom-phone-field';

const schema = yup.object().shape({
  name: yup
    .string()
    .required()
    .max(15, 'should be 15 characters long'),
  email: yup.string().email().required(),
  phone: yup.string(),
  company: yup.string().max(35, 'should be 35 characters long'),
  jobTitle: yup.string().max(35, 'should be 35 characters long'),
  notes: yup.string().max(255, 'should be 255 characters long'),
});

const LeadCaptureModal = ({ profile }) => {
  const [showMore, setShowMore] = useState(false);

  const [location, setLocation] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      jobTitle: '',
      notes: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    createLead,
    error,
    loading,
    showLeadForm,
    setShowLeadForm,
  } = useAppContext();

  navigator.geolocation.getCurrentPosition(function (position) {
    setLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  });

  const handleClose = () => {
    setShowLeadForm(false);
  };

  const onSubmit = (data) => {
    createLead({ ...data, user: profile.user, ...location });
  };

  return (
    <div>
      <Modal
        show={showLeadForm}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="text-center"
      >
        <Modal.Header closeButton onHide={handleClose}>
          <h5 className="m-auto">
            Share your info with {profile.name}
          </h5>
        </Modal.Header>
        <Modal.Body>
          {error && <Message variant="danger">{error}</Message>}

          <Form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <CustomTextField
              id="name"
              name="name"
              label="Name"
              placeholder="John Doe"
              errors={errors.name}
              control={control}
            />
            <CustomTextField
              id="email"
              name="email"
              label="Email"
              type="email"
              placeholder="example@gmail.com"
              errors={errors.email}
              control={control}
            />
            <CustomPhoneField
              control={control}
              name="phone"
              label="Phone Number"
              errors={errors}
            />
            {showMore ? (
              <>
                <CustomTextField
                  id="jobTitle"
                  name="jobTitle"
                  label="Job Title"
                  placeholder=""
                  errors={errors.jobTitle}
                  control={control}
                />
                <CustomTextField
                  id="company"
                  name="company"
                  label="Company"
                  placeholder=""
                  errors={errors.company}
                  control={control}
                />
                <CustomTextField
                  id="notes"
                  name="notes"
                  label="Message"
                  placeholder=""
                  errors={errors.notes}
                  control={control}
                  as="textarea"
                  rows={3}
                />
              </>
            ) : (
              <Form.Group controlId="add" className="text-left">
                <Button
                  variant="light"
                  onClick={(e) => {
                    setShowMore(true);
                  }}
                >
                  + ADD MORE
                </Button>
              </Form.Group>
            )}

            <Button type="submit" variant="primary">
              {loading ? <Loader /> : <>Continue</>}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <p className="m-auto">
            Your Card will not sell or share your details
          </p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LeadCaptureModal;
