export const reorderItems = (items) => {
  if (!items) {
    return [];
  }
  const sortedArray = [...items].sort((a, b) => {
    if (a.position === undefined && b.position === undefined) {
      return 0; // Both items have undefined position, consider them equal
    }
    if (a.position === undefined) {
      return 1; // Item `a` has undefined position, so it should come after `b`
    }
    if (b.position === undefined) {
      return -1; // Item `b` has undefined position, so it should come before `a`
    }

    return a.position - b.position; // Sort by position in ascending order
  });

  return sortedArray;
};
