import React from 'react';

const ProductCard = ({ product }) => {
  const imageUrl =
    product.image && product.image !== ''
      ? product.image
      : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`;
  return (
    <a
      href={product.url}
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: 'none',
        color: 'inherit',
        outline: 0,
        cursor: 'auto',
        margin: '10px',
      }}
    >
      <div
        className="d-flex flex-row text-left"
        style={{
          overflowWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all',
        }}
      >
        <div className="">
          <img
            src={imageUrl}
            alt={product.image}
            className="card"
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        </div>
        <div className="ml-3">
          <h6>{product.title}</h6>
          <strong>{product.price && `$${product.price}`}</strong>
          <br />
          {product.description}
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
