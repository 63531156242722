import Loader from 'components/loader';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AuthService from 'services/AuthService';

const Connect = ({ location }) => {
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const joinTeamAsync = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        const response = await AuthService.joinTeam(token);
        const resetPasswordToken = response.data.token;
        if (resetPasswordToken) {
          history.push(
            `/update-password?token=${resetPasswordToken}`
          );
        }
        setMessage("Successfully joined team");
      } catch (e) {
        setMessage(e.response.data.message);
      }
      setLoading(false);
    };

    joinTeamAsync(); // Call the async function immediately
  }, [location.search, history]);
  return (
    <div className="container ">
      <div className="row ">
        <div className="col-md-6 text-center m-auto">
          <div
            className="mt-5"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1>Team Invite</h1>
            <img
              src="/logo-dark.png"
              alt=""
              style={{ height: '150px' }}
            />
            <h6>{message !== '' && message}</h6>
            <br />
            {loading ? (
              <Loader />
            ) : (
              <Button href="https://dash.yourcard.au">
                Login Now
              </Button>
            )}

            <br />
            <span>
              For any questions, please email us at{' '}
              <a href="mailto:support@yourcard.au">
                support@yourcard.au
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connect;
