import LeadCaptureModal from 'components/profile/LeadCaptureModal';
import LinksList from 'components/profile/LinksList';
import ProductsList from 'components/profile/ProductsList';
import { useAppContext } from 'hooks/useAppContext';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './profile.css';
import { reorderItems } from 'helpers';
import Loader from 'components/loader';

const Profile = ({ match }) => {
  const id = match.params.id;

  // ** Hooks
  const { profile, links, products, getProfile, loading } =
    useAppContext();

  useEffect(() => {
    getProfile(id);
  }, [id, getProfile]);

  const connectHandler = () => {
    var urlString = `${process.env.REACT_APP_API_URL}/v1/profiles/contact-card/${profile.id}`;
    window.open(urlString, '_self');
  };

  var elem = document.getElementById('profile-cover');

  if (elem) {
    var rect = elem.getBoundingClientRect();
    var width = rect.width;

    // Calculate the desired height based on your logic
    var desiredHeight = width * 0.65; // Adjust the multiplier as needed

    // Set the calculated height
    elem.style.height = desiredHeight + 'px';
    elem.style.paddingTop = desiredHeight - 90 + 'px';

    console.log(rect.width);
    console.log('Set height to: ' + desiredHeight);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Your Card</title>
      </Helmet>
      {loading && <Loader />}
      {profile && (
        <section>
          <div className="m-auto col-sm-7 col-md-6 col-lg-5 col-xl-4 card rounded-0 px-0 my-0 text-center">
            <div
              style={{
                background: profile.themeColor
                  ? `linear-gradient(${profile.themeColor},transparent)`
                  : 'transparent',
              }}
            >
              <div
                id="profile-cover"
                className="profile-cover"
                style={{
                  backgroundImage: `url(${
                    profile.cover && profile.cover !== ''
                      ? profile.cover
                      : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                  })`,
                }}
              >
                <div
                  className="profile-image rounded-circle img-fluid"
                  style={{
                    border: `2px solid white`,
                    backgroundImage: `url(${
                      profile.image && profile.image !== ''
                        ? profile.image
                        : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                    })`,
                  }}
                >
                  {profile.logo && profile.logo !== '' && (
                    <img
                      style={{
                        border: `2px solid white`,
                      }}
                      src={
                        profile.logo && profile.logo !== ''
                          ? profile.logo
                          : `${process.env.PUBLIC_URL}/assets/cover_placeholder.png`
                      }
                      className="profile-logo rounded-circle img-fluid"
                      alt=""
                    />
                  )}
                </div>
              </div>

              <div
                className="px-4"
                style={{
                  marginTop: '50px',
                }}
              >
                <h4>{profile.name}</h4>
                <span className="text-muted d-block">
                  {profile?.jobTitle}{' '}
                  {profile?.jobTitle && profile?.company && '@'}{' '}
                  {profile?.company}
                </span>
                <span className="text-muted d-block">
                  {profile.location}
                </span>
                <span className="text-muted d-block mb-2">
                  {profile.bio}
                </span>
                <button
                  onClick={connectHandler}
                  className="btn btn-primary mb-3"
                  style={{
                    backgroundColor: `${
                      (profile.themeColor &&
                        profile.themeColor.toLowerCase() !==
                          '#ffffff' &&
                        profile.themeColor.toLowerCase() !==
                          '#f0f0f0' &&
                        profile.themeColor) ??
                      '#000000'
                    }`,
                    borderRadius: '20px',
                  }}
                >
                  Save Contact
                </button>
              </div>
            </div>

            <LinksList links={reorderItems(links)} />
            <ProductsList
              products={reorderItems(products)}
              category={profile.category}
            />

            <a
              href="https://yourcard.au"
              target="_blank"
              rel="noreferrer"
              className="btn btn-light m-3"
              style={{
                borderRadius: '20px',
              }}
            >
              Create your own profile
            </a>
          </div>

          <LeadCaptureModal profile={profile} />
        </section>
      )}
    </>
  );
};

export default Profile;
