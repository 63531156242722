import React from 'react';
import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CustomPhoneField = ({
  name,
  control,
  label,
  errors,
  hidden,
}) => {
  const { field } = useController({
    name: name,
    control,
    defaultValue: '',
  });
  return (
    <Form.Group
      controlId={name}
      className="mb-2"
      style={{
        textAlign: 'left',
      }}
      hidden={hidden}
    >
      <Form.Label>{label}</Form.Label>
      <PhoneInput
        {...field}
        country="au"
        value={field.value || ''}
        inputStyle={{
          fontSize: 16,
          width: '100%',
          height: '55px',
        }}
      />
      {errors && errors[name] && (
        <Form.Control.Feedback type="invalid">
          {errors[name].message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default CustomPhoneField;
